<template>
  <div class="mt-1 mx-3">
    <agency-setups></agency-setups>
  </div>
</template>

<script>
import AgencySetups from "@/components/profile/agency-setups/index.vue";

import { mapActions } from "vuex";

export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    AgencySetups,
  },
  created() {
    this.notifyQueue({
      text: "Profilo",
      path: "",
      level: 1,
    });
    this.notifyQueue({
      text: "Dati di Agenzia",
      path: "agency_setups.index",
      level: 2,
    });
  },
};
</script>
